import React from 'react';
import pageData from '@static/cms/pages/home.json';

const Template = require(`@templates/${pageData.template}`).default;

export default function Index(props) {
	const templateProps = {
		...props,
		pageContext: {
			...props.pageContext,
			...pageData
		}
	};
	return <Template {...templateProps} />;
}
